import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, throwError } from "rxjs";
import { environment } from "src/environments/environment";

export interface fetchDataOptions {
	populate?: string;
	filters?: any;
	pagination?: {
		page?: number;
		pageSize: number;
	};
	sort?: {
		field: string;
		order: string;
	};
}

@Injectable({ providedIn: "root" })
export class RestApiService {
	private apiURL = environment.apiURL;
	private apiURLUC = environment.apiURLUC;
	private UCToken = environment.UCToken;
	collectionSubject = new BehaviorSubject<any>(null);

	constructor(private httpClient: HttpClient) {}

	get(collection: string, options?: fetchDataOptions): Observable<any> {
		let populate = options?.populate ? `&${options?.populate}` : ``;
		let filters = options?.filters ? `&${options?.filters}` : ``;
		let pagination = options?.pagination != undefined ? `&pagination[page]=${options.pagination.page || 1}&pagination[pageSize]=${options.pagination.pageSize}` : ``;
		let sort = options?.sort != undefined ? `&sort=${options.sort.field}:${options.sort.order}` : `&sort[1]=id:desc`;
		return this.httpClient.get<any>(`${this.apiURL}/${collection}?q${populate}${filters}${pagination}${sort}`);
	}

	post(collection: string, data: any): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/${collection}`, { data: data }).pipe(catchError(this.handleError));
	}

	postFormData(collection: string, data: any): Observable<any> {
		return this.httpClient.post(`${this.apiURL}/${collection}`, data).pipe(catchError(this.handleError));
	}

	put(collection: string, itemId: number | string, data: any): Observable<any> {
		return this.httpClient.put(`${this.apiURL}/${collection}/${itemId}`, { data: data }).pipe(catchError(this.handleError));
	}

	putFormData(collection: string, itemId: number, data: any): Observable<any> {
		return this.httpClient.put(`${this.apiURL}/${collection}/${itemId}`, data).pipe(catchError(this.handleError));
	}

	delete(collection: string, id: number): Observable<any> {
		return this.httpClient.delete(`${this.apiURL}/${collection}/${id}`).pipe(catchError(this.handleError));
	}

	//----------------------------------------------------------------
	// Uniques Codes ======================================
	searchUC(code: string, brand): Observable<any> {
		let headers = new HttpHeaders({
			brand: brand,
			apiclient: "PMI360",
			// Authorization: `Bearer ${this.UCToken}`,
		});
		return this.httpClient.get<any>(`${this.apiURLUC}/uc/search/${code}`, { headers: headers }).pipe(catchError(this.handleError));
	}

	useUC(code: string, brand): Observable<any> {
		let headers = new HttpHeaders({
			brand: brand,
			apiclient: "PMI360",
			// Authorization: `Bearer ${this.UCToken}`,
		});
		return this.httpClient.put<any>(`${this.apiURLUC}/uc/use/${code}`, null, { headers: headers }).pipe(catchError(this.handleError));
	}

	handleError(errorResponse: HttpErrorResponse) {
		console.log("================== error====================");
		console.log(errorResponse);
		console.log("================== /error ==================");
		return throwError(errorResponse);
	}
}
