import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";

const routes: Routes = [
	{
		path: "",
		component: AdminComponent,
		children: [
			{ path: "", redirectTo: "campaigns", pathMatch: "full" },
			{
				path: "dashboard",
				loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
			},
			{
				path: "campaigns",
				loadChildren: () => import("./campaigns/campaigns.module").then((m) => m.CampaignsModule),
			},
			{
				path: "products",
				loadChildren: () => import("./products/products.module").then((m) => m.ProductsModule),
			},
			{
				path: "settings",
				loadChildren: () => import("./gestion/gestion.module").then((m) => m.GestionModule),
			},
			{
				path: "profile",
				loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {}
