const LAYOUT_TYPES = {
	VERTICAL: "vertical",
	HORIZONTAL: "horizontal",
	TWOCOLUMN: "twocolumn",
	SEMIBOX: "semibox",
};

const LAYOUT_MODE = {
	LIGHTMODE: "light",
	DARKMODE: "dark",
};

const LAYOUT_WIDTH_TYPES = {
	FLUID: "fluid",
	BOXED: "boxed",
};

const LAYOUT_POSITION_TYPES = {
	FIXED: "fixed",
	SCROLLABLE: "scrollable",
};

const LAYOUT_TOPBAR_COLOR_TYPES = {
	LIGHT: "light",
	DARK: "dark",
};

const SIDEBAR_SIZE = {
	LARGE: "lg",
	MEDIUM: "md",
	SMALL: "sm",
	SMALLHOVER: "sm-hover",
};

const SIDEBAR_VIEW = {
	DEFAULT: "default",
	DETACHED: "detached",
};

const SIDEBAR_COLOR = {
	LIGHT: "light",
	DARK: "dark",
	GRADIENT: "gradient",
	GRADIENT2: "gradient-2",
	GRADIENT3: "gradient-3",
	GRADIENT4: "gradient-4",
};

const SIDEBAR_IMAGE = {
	NONE: "none",
	IMAGE1: "image-1",
	IMAGE2: "image-2",
	IMAGE3: "image-3",
	IMAGE4: "image-4",
};

const SIDEBAR_VISIBILITY = {
	SHOW: "show",
};

const PERLOADER_TYPES = {
	ENABLE: "enable",
	DISABLE: "disable",
};

export {
	LAYOUT_TYPES,
	LAYOUT_MODE,
	LAYOUT_WIDTH_TYPES,
	LAYOUT_POSITION_TYPES,
	LAYOUT_TOPBAR_COLOR_TYPES,
	SIDEBAR_SIZE,
	SIDEBAR_VIEW,
	SIDEBAR_COLOR,
	SIDEBAR_IMAGE,
	SIDEBAR_VISIBILITY,
	PERLOADER_TYPES,
};
