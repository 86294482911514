import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class ZoomPreventionService {
	preventZoom() {
		document.addEventListener(
			"touchstart",
			(event: TouchEvent) => {
				if (event.touches.length > 1) {
					event.preventDefault();
				}
			},
			{ passive: false }
		);

		let lastTouchEnd = 0;
		document.addEventListener(
			"touchend",
			(event: TouchEvent) => {
				const now = Date.now();
				if (now - lastTouchEnd <= 300) {
					event.preventDefault();
				}
				lastTouchEnd = now;
			},
			{ passive: false }
		);

		document.addEventListener(
			"gesturestart",
			(event: Event) => {
				event.preventDefault();
			},
			{ passive: false }
		);
	}
}
