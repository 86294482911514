<div id="layout-wrapper" (window:resize)="onResize($event)">
	<!-- Top Bar -->
	<app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>

	<!-- Side Bar -->
	<app-sidebar></app-sidebar>

	<div class="main-content">
		<div class="page-content">
			<div class="container-fluid">
				<!-- Toast messages -->
				<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

				<!-- Router outlet -->
				<router-outlet></router-outlet>
			</div>
		</div>
		<!-- Footer -->
		<app-footer></app-footer>
	</div>
</div>

<!-- Right Side Bar -->
<!-- <app-rightsidebar (settingsButtonClicked)="onSettingsButtonClicked()"></app-rightsidebar> -->
