<main [ngStyle]="ngStyle">
	<!-- Header section -->
	@if (wheelHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			<h2 class="content-header-title">{{ headerTitle }}</h2>

			<div class="wheel-content">
				<div class="wheel-container">
					<div class="wheel-wrapper"></div>
				</div>

				<form [formGroup]="form" (ngSubmit)="onFooterBtnClick()" class="input-wrapper">
					<div class="input-group" [ngClass]="{ hidden: !isRegionRequired }">
						<label class="input-group-text bg-white border-0" for="inputGroupSelect01">
							<img src="/assets/las/images/local.png" alt="local icon" />
						</label>

						<select formControlName="region" class="form-select border-0" id="inputGroupSelect01" [(ngModel)]="selectedRegion" (change)="onRegionChange($event)">
							@if (lang == "ar") {
								<option value="" disabled selected hidden>اختيار جهتك</option>
								<option value="1">طنجة</option>
								<option value="2">الدار البيضاء</option>
								<option value="3">مراكش</option>
							} @else {
								<option value="" disabled selected hidden>Sélectionner ta région</option>
								<option value="1">Tangier</option>
								<option value="2">Casablanca</option>
								<option value="3">Marrakesh</option>
							}
						</select>
					</div>

					<div class="input-group-uc" [ngClass]="{ hidden: !isUCRequried }">
						<div class="input-group" style="height: 60px">
							<span class="input-group-text bg-white border-0 input-span-1">
								<img src="/assets/las/images/user-icon.png" class="icon" alt="user icon" />
							</span>

							<input
								formControlName="uc"
								type="text"
								id="wheelInputId"
								class="form-control border-0"
								[placeholder]="lang == 'ar' ? 'ادخل الكود الخاص بك' : 'Entrer ton unique code'"
								[(ngModel)]="wheelInput"
								autocomplete="off"
							/>

							<span class="input-group-text bg-white border-0 input-span-2">
								<img src="/assets/las/images/cigarette-box.png" class="icon" alt="" />
							</span>
						</div>

						<span class="input-warning-text" [class.opacity]="inputWarningMessage !== ''">
							<img class="input-icon-warning" src="/assets/las/images/exclamation.svg" alt="" />
							<span>{{ inputWarningMessage }}</span>
						</span>
					</div>
				</form>
			</div>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				[ngStyle]="{ color: colorCTA, 'background-color': bgColorCTA, border: borderCTA }"
				class="btn btn-danger footer-btn"
				[disabled]="handleButtonDisabled()"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (wheelFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>

<span class="online" *ngIf="isSending === true"></span>

<!-- Modal -->
<ng-template #modalEdit let-modal>
	<form [formGroup]="">
		<div class="modal-content">
			<div class="modal-body">
				<h3 style="text-align: center; margin-bottom: 24px; color: black">{{ winningProductTitle }}</h3>

				<div class="code-card">
					<img [src]="winningProductModal" alt="" style="width: 140px" />
				</div>
			</div>
		</div>
	</form>
</ng-template>
