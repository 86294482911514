import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "src/app/core/helpers/jwt.interceptor";
import { ToastrModule } from "ngx-toastr";

@NgModule({
	declarations: [AdminComponent],
	imports: [
		ToastrModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: "toast-bottom-right",
			preventDuplicates: true,
			closeButton: true,
		}),
		AdminRoutingModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
})
export class AdminModule {
	constructor() {
		defineElement(lottie.loadAnimation);
	}
}
