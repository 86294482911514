import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, ViewChild, ElementRef, inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";
import { JourneyOfflineService } from "../core/services/journey.offline.service";

@Component({
	selector: "app-qa",
	templateUrl: "./qa.component.html",
	styleUrl: "qa.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class QAComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	ngStyle: any;
	backendURL = environment.backendURL;

	form: FormGroup;

	QAData: any;
	footerBtnText: string;
	footerText: string;

	campaignHeader: any;
	QAHeaderOpt: boolean = true;
	QAFooterOpt: boolean = true;

	// LAS Log
	lasData: any;
	stepName: string;
	QALogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Fake data
	selectedAnswer: any | null = null;
	currentQuestionIndex: number = 0;
	showResult: boolean = false;
	questions: any;

	correctAnswerText: string;
	wrongAnswerText: string;

	// Final Step
	isFinalStep: boolean = false;

	// Routing config
	isAnyNextRoute: any;

	// Campaign CTA Text
	ctaNextText: string;
	ctaRetryText: string;

	qaRouteID: any;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService,
		private journeyOfflineService: JourneyOfflineService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		// Get the ID from the activated route and mark it as visited route within the id
		this.activatedRoute.paramMap.subscribe((params) => {
			this.qaRouteID = params.get("id") || "";

			if (this.qaRouteID) {
				this.routeGuard.markRouteAsVisited(`qa/${this.qaRouteID}`);
			} else {
				this.routeGuard.markRouteAsVisited("qa");
			}

			// Check if this route is the final step
			this.isFinalStep = this.routePositionService.getRouteOrderPosition(this.qaRouteID ? `qa/${this.qaRouteID}` : "qa");

			// Add step to the journey offline
			this.journeyOfflineService.addStep(this.qaRouteID ? `qa/${this.qaRouteID}` : "qa", this.isFinalStep);

			this.initializeLogVerfication();

			this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
				if (data != null) {
					this.campaignHeader = data.campaign.theme.header;
					this.footerText = data.campaign.theme.footer;

					this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
					this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

					this.QAData = data.campaign.steps.filter((e) => e.__component === "screen.qa")[this.qaRouteID ? this.qaRouteID - 1 : 0];

					this.QAHeaderOpt = this.QAData.isHeaderEnabled;
					this.QAFooterOpt = this.QAData.isFooterEnabled;

					this.correctAnswerText = this.QAData.correctAnswerText;
					this.wrongAnswerText = this.QAData.wrongAnswerText;

					this.questions = this.QAData.questions;

					// Log step
					const stepLebelFormated = this.removeSpacesBetweenWords(this.QAData.stepLabel);
					if (!this.QALogVerfication[stepLebelFormated]) {
						this.stepName = this.QAData.stepLabel;
						this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
							if (data != null) {
								this.lasData = data;
								this.logStep(this.lasData.journey, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
							}
						});

						this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
					}

					// Set CTA text
					this.footerBtnText = this.QAData?.ctaText;
					// if (this.QAData?.ctaText) {
					// 	this.footerBtnText = this.QAData?.ctaText;
					// } else {
					// 	this.footerBtnText = this.ctaNextText;
					// }

					// Set theme --------------------
					const theme = data.campaign.theme;
					const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.QAData);
					this.ngStyle = ngStyle;
					this.backgroundImageUrl = backgroundImageUrl;
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		if (this.selectedAnswer) {
			if (!this.showResult) {
				// Show the result
				this.showResult = true;

				// if (!(this.currentQuestionIndex < this.questions.length - 1)) {
				// 	// Set CTA Text
				// 	this.isAnyNextRoute = this.routingOrderService.getNextRoute(this.qaRouteID ? `qa/${this.qaRouteID}` : "qa");
				// 	if (!this.isAnyNextRoute) {
				// 		this.footerBtnText = this.ctaRetryText;
				// 	}
				// }
			} else {
				// Move to the next question if available
				if (this.currentQuestionIndex < this.questions.length - 1) {
					this.currentQuestionIndex++;
					this.selectedAnswer = null;
					this.showResult = false;
				} else {
					// You can add additional logic here for when all questions are answered
					console.log("Quiz completed!");

					// If is final step remove the log verfication
					if (this.isFinalStep) {
						localStorage.removeItem("logVerfication");
						localStorage.removeItem("visitedRoutes");
					}

					// Get the currentPath and check if is a dynamic route or regular route
					const currentPath = this.activatedRoute.snapshot.routeConfig.path;
					const actualPath = currentPath.includes(":id") ? currentPath.replace(":id", this.qaRouteID) : currentPath;

					this.routingOrderService.navigateToNext(actualPath);
				}
			}
		} else {
			// Opt, you can show a message asking the user to select an answer
			console.log("Please select an answer before proceeding.");
		}
	}

	selectAnswer(response: any) {
		this.selectedAnswer = response;
		this.showResult = false;
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	currentQuestion() {
		// Return the current question base on the current index
		if (this.questions) {
			return this.questions[this.currentQuestionIndex];
		}
	}

	correctAnswer() {
		// Return the correct answer inside the questions
		return this.currentQuestion().answers.find((response: any) => response.isCorrect)!;
	}

	isCorrectAnswer() {
		if (this.correctAnswer()?.answer === this.selectedAnswer?.answer) {
			return true;
		} else {
			return false;
		}
	}

	logStep(journeyId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(journeyId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey qa created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.QALogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.QALogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.QALogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
