import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { LasService } from "./core/services/las.service";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { environment } from "src/environments/environment";

import { DOCUMENT } from "@angular/common";

import { FingerprintService } from "src/app/core/services/finger-print.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppStatusService } from "src/app/core/services/standalone.listnet.service";

@Component({
	selector: "app-las",
	templateUrl: "./las.component.html",
	styleUrls: [
		"../../../../src/assets/las/scss/las.scss",
		"../../../../node_modules/slick-carousel/slick/slick.scss",
		"../../../../node_modules/slick-carousel/slick/slick-theme.scss",
		"../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css",
	],
	encapsulation: ViewEncapsulation.None,
})
export class LasComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	uuid: string;
	publicUUID: string;
	hostessUUID: string;

	campaignFontFamily: string;
	campaignHeaderCss: any;
	campaignColor: string;
	campaignBgColor: string;
	isStandalone = false;
	isOnline = false;

	ctaTextColor: string;
	ctaBgColor: string;
	ctaBorderColor: string;
	compagnFont: string;
	slug: string;

	lasData: any;
	campaignId: number;
	lasSlug: any;
	verificationMethod: string;
	yotiLogVerfication: any;

	backendSteps: any;
	backendSlug: any;
	routeOrder: any;

	hostessInfo: any;

	constructor(
		private lasService: LasService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private restApiService: RestApiService,
		private fingerprintService: FingerprintService,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
		private routingOrderService: RoutingOrderService,
		private ngxLoader: NgxUiLoaderService,
		private appStatusService: AppStatusService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	async ngOnInit() {
		// font family loader.
		this.fontFamilyLoader();

		// Get the device uuid
		this.uuid = await this.fingerprintService.getDeviceId();

		// Generate random device uuid
		this.publicUUID = await this.fingerprintService.getRandomDeviceId();

		// Generate random hostess device uuid
		this.hostessUUID = await this.fingerprintService.getRandomDeviceIdHostess();

		// Log verfication initialize
		this.initializeLogVerfication();

		// Initial the routing order service
		this.routingOrderService.callThisService();

		// check stand alone mode from service
		this.appStatusService.isStandalone$.subscribe((isStandalone) => {
			this.isStandalone = isStandalone;
			console.log("Standalone mode:", this.isStandalone);
			this.syncOfflineData();
		});

		// update online offline status
		this.appStatusService.isOnline$.subscribe((isOnline) => {
			this.isOnline = isOnline;
			console.log("Online status:", this.isOnline);
			this.syncOfflineData();
		});

		this.activatedRoute.params.pipe(takeUntil(this.unsubscriber)).subscribe((params: Params) => {
			if (params["campaign-slug"] !== undefined) {
				this.restApiService
					.get(`campaigns/${params["campaign-slug"]}`)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe((response) => {
						this.campaignId = response.data?.id;
						this.lasSlug = response.data?.slug;
						this.campaignFontFamily = response.data?.theme?.fontFamily;

						this.verificationMethod = response.data?.ageVerificationMethod;
						localStorage.setItem("verificationMethod", this.verificationMethod);

						const currentSlug = JSON.parse(localStorage.getItem("slug"));
						if (currentSlug !== null) {
							if (currentSlug !== this.lasSlug) {
								// Check the campaign verification method
								if (this.verificationMethod === "yoti") {
									// Clear all the data
									localStorage.clear();

									// Set the new public UUID
									this.uuid = this.publicUUID;
									localStorage.setItem("device_fingerprint", this.publicUUID);
									localStorage.setItem("slug", JSON.stringify(this.lasSlug));
								} else if (this.verificationMethod === "hav") {
									// Clear all the data except the 'hostessInfo'
									this.clearLocalStorageExcept("hostessInfo");

									// Set the new hostess UUID
									this.uuid = this.hostessUUID;
									localStorage.setItem("device_fingerprint", this.hostessUUID);
									localStorage.setItem("slug", JSON.stringify(this.lasSlug));
								}
							}
						} else {
							localStorage.setItem("slug", JSON.stringify(this.lasSlug));
						}

						// Set the campaign cta theme colors
						this.ctaTextColor = response.data?.theme?.cta.textColor;
						this.ctaBgColor = response.data?.theme?.cta.bgColor;
						this.ctaBorderColor = response.data?.theme?.cta.borderColor;

						// Set the campaign theme color and call the updateColor function
						this.campaignColor = response.data.theme?.color;

						// Set the campaign theme color and call the updateColor function
						this.campaignBgColor = response.data.theme?.bgColor;

						// Set the campaign slug
						this.slug = response.data.slug;

						// Set the campaign font family
						this.compagnFont = this.campaignFontFamily;

						// Set the campaign header css
						this.campaignHeaderCss = response.data?.theme?.header?.customCSS;
						this.addStylesToBody(this.campaignHeaderCss);

						// Update color of the app variables
						this.updateColor();

						// Set direction based on language
						this.setDirection(response.data.lang); // response.data.lang

						this.lasService.campaignSubject.next({ campaign: response.data });

						// Redirect to the status component base on the campaign status
						if (!this.isDateInRange(response.data?.startDate, response.data?.endDate) || !response.data?.isActif) {
							this.router.navigate([`las/${params["campaign-slug"]}/campaign-access-forbidden`]);
							return;
						} else if (this.router.url.includes("/campaign-access-forbidden")) {
							// Retrieve the first route dynamically
							const dynamicFirstRoute = JSON.parse(localStorage.getItem("routeOrder"))[0];
							this.router.navigate([`las/${params["campaign-slug"]}/${dynamicFirstRoute}`]);
						}

						// Check/register UUID in backend API
						this.hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;
						if (this.hostessInfo) {
							this.restApiService
								.get(`lases/${this.uuid}/${this.campaignId}`)
								.pipe(takeUntil(this.unsubscriber))
								.subscribe({
									next: (response) => {
										// Dispatch lasSubject for application wide uses
										this.lasService.lasSubject.next(response.data);
									},
								});
						} else {
							this.lasService
								.checkAndRegisterLASByUUID(this.uuid, null, this.campaignId)
								.pipe(takeUntil(this.unsubscriber))
								.subscribe({
									next: (response) => {
										// Get LAS data
										this.lasData = response.data;

										// Check verificationMethod if is 'yoti' or not
										if (this.lasSlug && this.verificationMethod === "yoti") {
											this.activatedRoute.queryParamMap.subscribe((params) => {
												// Get verification result & token from the url
												const yotiVerification = params.get("yotiVerification");

												// Check if verfication success
												if (yotiVerification === "success") {
													// Update the age verifaction endpoint
													this.restApiService
														.put("lases/set-age-verified", `${this.uuid}`, {})
														.pipe(takeUntil(this.unsubscriber))
														.subscribe(() => {
															// Set 'isAgeVerified' to true
															localStorage.setItem("isAgeVerified", "true");

															// Update the lasSubject data
															this.lasData["isAgeVerified"] = true;
															this.lasService.lasSubject.next(this.lasData);

															// Log step yoti age verification
															this.logStep(this.lasData.journey, "Yoti age verification");
															this.updateLogVerfication("Yoti age verification", "true");
														});
												} else {
													const isAgeVerified = this.lasData.isAgeVerified;

													if (isAgeVerified) {
														localStorage.setItem("isAgeVerified", "true");
													} else {
														localStorage.setItem("isAgeVerified", "false");
													}

													// Update the lasSubject data
													this.lasService.lasSubject.next(this.lasData);
												}
											});
										}
									},
									error: (error) => {
										console.error("Error checking/registering UUID:", error);
									},
								});
						}
					});
			}
		});
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Functions
	// --------------------------------------------------------------------------------------------
	addStylesToBody(styles: string) {
		const style = document.createElement("style");
		style.textContent = styles;

		document.body.appendChild(style);
	}

	syncCompletedJourneys() {
		// Start the loading effect
		this.ngxLoader.start();

		// Get 'hostessInfo' from localStorage
		const hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;

		// Get 'completedJourneys' and 'currentJourney' from LocalStorage
		const completedJourneys = JSON.parse(localStorage.getItem("completedJourneys"));
		const currentJourney = localStorage.getItem("currentJourney") ? JSON.parse(localStorage.getItem("currentJourney")) : null;

		// Push the 'currentJourney' to the 'completedJourneys'
		// if (currentJourney) {
		// 	completedJourneys.push(currentJourney);
		// }

		if (completedJourneys && hostessInfo) {
			this.restApiService
				.post("lases/sync-offline-data", { hostess: hostessInfo?.user.id, completedJourneys })
				.pipe(takeUntil(this.unsubscriber))
				.subscribe({
					next: (response) => {
						console.log("POSTING JOURNEYS OFFLINE: ", response);

						// Clear the 'currentJourney' and 'completedJourneys' from LocalStorage
						localStorage.removeItem("currentJourney");
						localStorage.removeItem("completedJourneys");

						// End the loading effect
						this.ngxLoader.stop();
					},
					error: (error) => {
						console.log("ERROR WHEN POSTING JOURNEYS OFFLINE: ", error);

						// Clear the 'currentJourney' and 'completedJourneys' from LocalStorage
						localStorage.removeItem("currentJourney");
						localStorage.removeItem("completedJourneys");

						// End the loading effect
						this.ngxLoader.stop();
					},
				});
		}
	}

	syncOfflineData() {
		// Retrieve 'completedJourneys' from LocalStorage
		const completedJourneys = JSON.parse(localStorage.getItem("completedJourneys"));

		// condition to check if standalone and is online to post stored journeys
		if (this.isStandalone && this.isOnline && completedJourneys && completedJourneys?.length > 0) {
			this.syncCompletedJourneys();
		}
	}

	// Load specific font family.
	fontFamilyLoader() {
		const font = new FontFace("Flood", "url(../../../../assets/las/fonts/flood.otf)");

		// Load the font family
		font.load()
			.then((loadedFont) => {
				// Type assertion to any to bypass TypeScript checking
				(document.fonts as any).add(loadedFont);
				console.log("font family loaded", loadedFont);
			})
			.catch((error) => {
				console.error("Font loading failed:", error);
			});
	}

	setFullHeight() {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	}

	// Direction functionality
	setDirection(lang: string) {
		const isRTL = lang === "ar";
		this.document.documentElement.dir = isRTL ? "rtl" : "ltr";
		this.document.body.dir = isRTL ? "rtl" : "ltr";

		// Add a class to the body for additional styling
		if (isRTL) {
			this.document.body.classList.add("rtl");
			this.document.body.classList.remove("ltr");
		} else {
			this.document.body.classList.add("ltr");
			this.document.body.classList.remove("rtl");
		}
	}

	// Set colors in variables dynamically
	updateColor() {
		this.renderer.setProperty(
			this.document.documentElement,
			"style",
			`
--campaign-color: ${this.campaignColor};
--campaign-BgColor: ${this.campaignBgColor};
--cta-BorderColor: ${this.ctaBorderColor};
--cta-textColor: ${this.ctaTextColor};
--cta-BgColor: ${this.ctaBgColor};
--campaign-font: ${this.compagnFont};
--friend-names-font: "Flood";
            `
		);
	}

	// Check if date is between the start and end date
	isDateInRange(startDate, endDate) {
		let currentDate = new Date();
		let start = new Date(startDate);
		let end = new Date(endDate);

		// Set time to midnight for accurate date comparison
		// currentDate.setHours(0, 0, 0, 0);
		// start.setHours(0, 0, 0, 0);
		// end.setHours(0, 0, 0, 0);

		return currentDate >= start && currentDate <= end;
	}

	clearLocalStorageExcept(...keysToKeep: string[]) {
		// Store the values we want to keep
		const valuesToKeep = keysToKeep.reduce(
			(acc, key) => {
				const value = localStorage.getItem(key);
				if (value !== null) {
					acc[key] = value;
				}
				return acc;
			},
			{} as Record<string, string>
		);

		// Clear everything
		localStorage.clear();

		// Restore the values we want to keep
		Object.entries(valuesToKeep).forEach(([key, value]) => {
			localStorage.setItem(key, value);
		});
	}

	// Log step
	logStep(journeyId: number, stepLabel: string) {
		this.lasService
			.logStep(journeyId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey yoti created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.yotiLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.yotiLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.yotiLogVerfication));
	}
}
