import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LasComponent } from "./las.component";
import { TutorialComponent } from "./tutorial/tutorial.component";
import { GameChooserComponent } from "./game-chooser/game-chooser.component";
import { PolicyComponent } from "./policy/policy.component";
import { ContentComponent } from "./content/content.component";
import { ResultComponent } from "./wheel/result/result.component";

import { DataCollectionComponent } from "./data-collection/data-collection.component";
import { HostessAuthComponent } from "./hostess-auth/hostess-auth.component";
import { HostessAuthGuard } from "./core/guards/hostess-auth.guard";
import { WheelGuard } from "./core/guards/wheel.guard";
import { RouteOrderGuard } from "./core/guards/route.guard";
import { AgeVerificationGuard } from "./core/guards/age-verification.guard";
import { LasGuard } from "./core/guards/las.guard";
import { RouteOrderGameGuard } from "./core/guards/route-game.guard";
import { QAComponent } from "./q&a/qa.component";
import { CampaignAccessComponent } from "./campaign-access/campaign-access.component";

const routes: Routes = [
	{
		path: "",
		component: LasComponent,
		canActivate: [HostessAuthGuard],

		children: [
			{ path: "hostess", component: HostessAuthComponent },

			{ path: "policy", component: PolicyComponent, canActivate: [AgeVerificationGuard] },

			{ path: "tutorial", component: TutorialComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "tutorial/:id", component: TutorialComponent, canActivate: [RouteOrderGuard, LasGuard] },

			{ path: "game-chooser", component: GameChooserComponent, canActivate: [RouteOrderGuard, LasGuard] },

			{ path: "content", component: ContentComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "content/:id", component: ContentComponent, canActivate: [RouteOrderGuard, LasGuard] },

			{ path: "qa", component: QAComponent, canActivate: [RouteOrderGuard, LasGuard] },
			{ path: "qa/:id", component: QAComponent, canActivate: [RouteOrderGuard, LasGuard] },

			{ path: "data-collection", component: DataCollectionComponent, canActivate: [RouteOrderGuard, LasGuard] },

			{ path: "wheel/result", component: ResultComponent },

			{ path: "campaign-access-forbidden", component: CampaignAccessComponent },

			{
				path: "wheel",
				canActivate: [RouteOrderGuard, LasGuard, WheelGuard],
				loadChildren: () => import("./wheel/wheel.module").then((m) => m.WheelModule),
			},
			{
				path: "reveal-touch",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/reveal-touch/reveal-touch.module").then((m) => m.RevealTouchModule),
			},
			{
				path: "choose-venue",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/choose-venue/choose-venue.module").then((m) => m.ChooseVenueModule),
			},
			{
				path: "spot-mlb",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/spot-mlb/spot-mlb.module").then((m) => m.SpotMlbModule),
			},
			{
				path: "friend-names",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/friends-names/friends-names.module").then((m) => m.FriendsNamesModule),
			},
			{
				path: "friend-preferences",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/friends-preferences/friends-preferences.module").then((m) => m.FriendsPreferencesModule),
			},
			{
				path: "style-pack",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/style-pack/style-pack.module").then((m) => m.StylePackModule),
			},
			{
				path: "tobacco",
				canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/tobacco/tobacco.module").then((m) => m.TobaccoModule),
			},
			{
				path: "pick-your-style",
				// canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/pick-your-style/pick-your-style.module").then((m) => m.PickYourStyleModule),
			},
			{
				path: "what-in-your-bag",
				// canActivate: [RouteOrderGameGuard, LasGuard],
				loadChildren: () => import("./games/what-in-your-bag/what-in-your-bag.module").then((m) => m.WhatInYourBagModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LasRoutingModule {}
