import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class RouteOrderGameGuard {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const slug = JSON.parse(localStorage.getItem("slug"));
		const routeOrder = this.getRouteOrder();
		const routeVisited = this.getVisitedRoutes();
		const currentRoute = route.routeConfig.path;
		const currentIndex = routeOrder.indexOf(currentRoute);

		const isGameIncluded = routeOrder.includes(currentRoute);

		if (isGameIncluded) {
			if (currentIndex === -1) {
				console.warn(`Route ${currentRoute} not found in routeOrder`);
				return true; // Allow access if the route is not in the order
			}

			if (currentIndex === 0) {
				return true; // Always allow access to the first route
			}

			const previousRoute = routeOrder[currentIndex - 1];
			const visitedRoutes = this.getVisitedRoutes();

			if (!visitedRoutes.includes(previousRoute)) {
				console.log(`las/${slug}/${previousRoute}`);

				return this.router.createUrlTree([`las/${slug}/${previousRoute}`]);
			}

			return true;
		} else {
			const isGameChooserIncluded = routeVisited.includes("game-chooser");

			if (isGameChooserIncluded) {
				return true;
			} else {
				return this.router.createUrlTree([`las/${slug}/game-chooser`]);
			}
		}
	}

	private getRouteOrder(): string[] {
		const storedOrder = localStorage.getItem("routeOrder");
		return storedOrder ? JSON.parse(storedOrder) : [];
	}

	private getVisitedRoutes(): string[] {
		const visitedRoutes = localStorage.getItem("visitedRoutes");
		return visitedRoutes ? JSON.parse(visitedRoutes) : [];
	}

	private getRouteFromUrl(url: string): string {
		const segments = url.split("/");
		return segments[1] || ""; // Get the first segment after the leading slash
	}

	// Public method to mark a route as visited
	public markRouteAsVisited(route: string): void {
		const visitedRoutes = this.getVisitedRoutes();
		if (!visitedRoutes.includes(route)) {
			visitedRoutes.push(route);
			localStorage.setItem("visitedRoutes", JSON.stringify(visitedRoutes));
		}
	}
}
