<footer class="footer">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="text-center d-none_ d-sm-block_">
					<span class="fs-12">PMI360 &copy;</span>
				</div>
			</div>
		</div>
	</div>
</footer>
