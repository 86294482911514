import { NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";

// Auth
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Language
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

// Store
import { rootReducer } from "./store";
import { StoreModule } from "@ngrx/store";

import { AppRoutingModule } from "./app-routing.module";
import { AdminModule } from "./apps/admin/admin.module";
import { LasModule } from "./apps/las/las.module";
import { LayoutsModule } from "./layouts/layouts.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxUiLoaderModule } from "ngx-ui-loader";

export function createTranslateLoader(http: HttpClient): any {
	return new TranslateHttpLoader(http, "assets/admin/i18n/", ".json");
}

function isAppInstalled() {
	return window.matchMedia("(display-mode: standalone)").matches;
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		NgxUiLoaderModule.forRoot({
			fgsColor: "#ff073a", // Set foreground spinner color (e.g., orange-red)
			fgsType: "pulse", // Spinner type (options: ball-spin-clockwise, three-bounce, etc.)
			fgsSize: 50, // Spinner size (in pixels)

			// Background spinner (optional background loader) settings
			bgsColor: "#ff073a", // Background spinner color
			bgsPosition: "bottom-right", // Background spinner position
			bgsSize: 60, // Background spinner size (in pixels)
			bgsType: "ball-spin-clockwise", // Background spinner type

			// Progress bar settings
			pbColor: "#ff073a", // Progress bar color (e.g., blue)
			pbThickness: 2,
		}),
		AppRoutingModule,
		AdminModule,
		LasModule,
		LayoutsModule,
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			defaultLanguage: "fr",
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		StoreModule.forRoot(rootReducer),
		ServiceWorkerModule.register("ngsw-worker.js", {
			// Check in the app is installed
			enabled: isAppInstalled(),
			registrationStrategy: "registerWhenStable:30000",
		}),
		ServiceWorkerModule.register("custom-service-worker.js", {
			// Check in the app is installed
			enabled: isAppInstalled(),
			registrationStrategy: "registerWhenStable:30000",
		}),
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
