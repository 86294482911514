import { AbstractControl, ValidatorFn } from "@angular/forms";

export function phoneNumberValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (!control.value) {
			return null;
		}
		const phoneNumber = control.value.replace(/\s/g, ""); // Remove any spaces
		const valid = /^0[1-9]\d{8}$/.test(phoneNumber);
		return valid ? null : { invalidPhone: { value: phoneNumber, valid: false } };
	};
}
