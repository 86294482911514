import { Pipe, PipeTransform, NgModule, LOCALE_ID } from "@angular/core";
import { DatePipe, CommonModule } from "@angular/common";

@Pipe({
	name: "dateFormatPipe",
})
export class dateFormatPipe implements PipeTransform {
	constructor() {}

	transform(value: string, format: string = "dateTime") {
		const d = new DatePipe("en-US");
		var dateFormat = format == "dateTime" ? "HH:mm - dd/MM/yyyy" : "dd/MM/yyyy";
		return d.transform(value, dateFormat, "UTC+1");
	}
}

@NgModule({
	declarations: [dateFormatPipe],
	imports: [CommonModule],
	providers: [{ provide: LOCALE_ID, useValue: "en-US" }],
	exports: [dateFormatPipe],
})
export class dateFormatPipeModule {}
