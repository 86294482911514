// routing-order.service.ts
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "src/app/apps/las/core/services/las.service";
import { FingerprintService } from "./finger-print.service";

@Injectable({
	providedIn: "root",
})
export class RoutingOrderService {
	unsubscriber: Subject<any> = new Subject<any>();
	private routeOrder: string[] = [];
	private routeOrderKeys: string[] = [];

	backendSteps: any[];
	backendSlug: string;

	isStandalone: boolean = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private fingerprintService: FingerprintService
	) {
		// Extract the route order from the backend data
		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.backendSteps = data.campaign.steps;
				this.backendSlug = data.campaign.slug;

				const stepToRouteMap = {
					"screen.policy": "policy",
					"screen.tutorial": "tutorial",
					"screen.game-chooser": "game-chooser",
					"screen.content": "content",
					"screen.data-collection": "data-collection",
					"screen.qa": "qa",
					"wheel.wheel": "wheel",
					"game-reveal-touch.reveal-touch": "reveal-touch",
					"game-spot-mlb.spot-mlb": "spot-mlb",
					"game-choose-venue.choose-venue": "choose-venue",
					"game-friend-name.friend-name": "friend-names",
					"game-friend-preference.friend-preference": "friend-preferences",
					"game-style-pack.style-pack": "style-pack",
					"game-tobacco-quality.tobacco-quality": "tobacco",
					"game-pick-your-style.pick-your-style": "pick-your-style",
					"game-whats-in-your-bag.whats-in-your-bag": "what-in-your-bag",
				};

				// Return steps and games as value
				this.routeOrder = this.backendSteps.map((step) => stepToRouteMap[step.__component]);

				// Return steps and games as key
				this.routeOrderKeys = this.backendSteps.map((step) => {
					const route = stepToRouteMap[step.__component];
					return Object.keys(stepToRouteMap).find((key) => stepToRouteMap[key] === route);
				});

				function processRoutesByGames(routes: any) {
					// Count games by checking the keys
					const gamesCount = routes.filter((key: any) => key.startsWith("game-")).length;

					// After checking keys, always return values but based on different conditions
					if (gamesCount === 0) {
						// If no game keys, return all values
						localStorage.setItem("gamesType", "mono");
						return routes.map((key: any) => stepToRouteMap[key]);
					}

					if (gamesCount === 1) {
						// If one game key, return all values
						localStorage.setItem("gamesType", "mono");
						return routes.map((key: any) => stepToRouteMap[key]);
					}

					if (gamesCount > 1) {
						// If multiple game keys, return values but exclude those whose keys start with 'game-'
						localStorage.setItem("gamesType", "multi");
						return routes.filter((key: any) => !key.startsWith("game-")).map((key: any) => stepToRouteMap[key]);
					}
				}

				this.routeOrder = processRoutesByGames(this.routeOrderKeys);

				// Check if any duplicated routes in route order
				this.routeOrder = this.handleDuplicateRoutes(this.routeOrder);

				localStorage.setItem("routeOrder", JSON.stringify(this.routeOrder));

				if (this.router.url.endsWith(`/las/${this.backendSlug}`) && this.routeOrder[0]) {
					this.router.navigate([`/las/${this.backendSlug}/${this.routeOrder[0]}`], { relativeTo: this.activatedRoute });
				}
			}
		});
	}

	callThisService() {}

	handleDuplicateRoutes(routes: string[]): string[] {
		const duplicates: { [key: string]: number } = {};
		const result = [...routes];

		for (let i = 0; i < result.length; i++) {
			const route = result[i];
			if (routes.indexOf(route) !== routes.lastIndexOf(route)) {
				duplicates[route] = (duplicates[route] || 0) + 1;
				result[i] = `${route}/${duplicates[route]}`;
			}
		}

		return result;
	}

	getNextRoute(currentPath: string): string | null {
		// Get the index of the current path in route order
		const currentIndex = this.routeOrder.indexOf(currentPath);
		// Check if is next route available
		if (currentIndex < this.routeOrder.length - 1) {
			return this.routeOrder[currentIndex + 1];
		}
		return null;
	}

	checkStandaloneMode(): void {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			this.isStandalone = true;
		}
	}

	async navigateToNext(currentPath: string): Promise<void> {
		const nextRoute = this.getNextRoute(currentPath);

		if (nextRoute) {
			this.router.navigate([`/las/${this.backendSlug}/${nextRoute}`], { relativeTo: this.activatedRoute });
		} else {
			const hostessInfo = localStorage.getItem("hostessInfo") ? JSON.parse(localStorage.getItem("hostessInfo")) : null;

			// Check if the user is hostess or yoti
			if (hostessInfo) {
				this.checkStandaloneMode();

				// Check if the hostess is PWA or Web
				if (this.isStandalone) {
					this.router.navigate([`/las/${this.backendSlug}/${this.routeOrder[0]}`], { relativeTo: this.activatedRoute });
				} else {
					location.href = `/las/hostess`;
				}
			} else {
				const uuid = await this.fingerprintService.getDeviceId();

				this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
					if (data !== null) {
						console.log(data);
						const campaignId = data.campaign?.id;

						// Register UUID in backend API
						this.lasService
							.checkAndRegisterLASByUUID(uuid, null, campaignId)
							.pipe(takeUntil(this.unsubscriber))
							.subscribe({
								next: (response) => {
									const lasData = response.data;

									// Update the lasSubject, then redirect to the next route
									this.lasService.lasSubject.next(lasData);
									this.router.navigate([`/las/${this.backendSlug}/${this.routeOrder[0]}`], { relativeTo: this.activatedRoute });

									// Log las/hostess
									console.log("las loged in");
								},
								error: (error) => {
									console.error("Error checking/registering UUID:", error);
								},
							});
					}
				});
			}
		}
	}
}
