import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ThemeService {
	// Store the backend URL as a private property
	private backendURL: string = environment.backendURL;

	constructor() {}

	getThemeStyles(theme: any, componentData: any) {
		// Set it with the default background image from the theme
		let bgImageUrl = this.backendURL + theme.bgImg.url;

		// Check the component has its own background image, use that instead
		if (componentData.bgImg != null) {
			bgImageUrl = this.backendURL + componentData.bgImg.url;
		}

		// Return an object with the calculated styles
		return {
			ngStyle: {
				"background-image": `url(${bgImageUrl})`,
				"background-size": "cover",
				"background-position": "top center",
				color: theme.textColor,
			},
			backgroundImageUrl: bgImageUrl,
		};
	}
}
