import { AfterViewInit, Component, ElementRef, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { Wheel } from "spin-wheel-ts";

import { environment } from "src/environments/environment";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { LasService } from "../../core/services/las.service";
import { ThemeService } from "../../core/services/theme.service";
import { RouteOrderGuard } from "../../core/guards/route.guard";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { RoutePositionService } from "../../core/services/route.position.service";
import { JourneyOfflineService } from "../../core/services/journey.offline.service";
import { CampaignDistributionService } from "src/app/core/services/campaign-distribution.service";

@Component({
	selector: "app-wheel",
	templateUrl: "./wheel.component.html",
	styleUrl: "./wheel.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class WheelComponent implements OnInit, AfterViewInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	wheelInput: string = "";
	selectedRegion: string = "";
	winningProductOffline: any;
	campaignHeader: any;
	campaignTheme: any;
	wheel: any;
	wheelSpinned = false;
	wheelSpinning = false;
	wheelData: any = null;
	headerTitle: string;
	footerBtnText: string;
	footerText: string;
	wheelHeaderOpt: boolean;
	wheelFooterOpt: boolean;

	isRegionRequired: boolean = false;
	isUCRequried: boolean = false;

	isOnline = false;
	isStandalone = false;
	isSending = false;
	wheelSpinnedOffline = false;

	products: any;
	props: any;
	slug: string;

	form: FormGroup;
	isUcNotFound: boolean = false;
	isUcUsed: boolean = false;
	isBtnDisabled: boolean = false;
	winningProduct: any = "";

	inputWarningMessage: string = "";

	// LAS Log
	lasData: any;
	stepName: string;
	wheelLogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Primary & Secondary colors
	primaryColor: string = "#B17457";
	secondaryColor: string = "#D8D2C2";

	lang: string = "fr";

	isEmptyProduct: boolean = false;

	// Offline mode
	winningProductModal: string;
	winningProductTitle: string;
	footerClickCount: number = 0;

	// Final step
	isFinalStep: boolean = false;

	// Campaign
	campaign: any;

	// Modal
	@ViewChild("modalEdit", { static: true }) modalEdit: ElementRef;
	modalEditRef: NgbModalRef;
	editModalSize: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private fb: FormBuilder,
		private restApiService: RestApiService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private modalService: NgbModal,
		private routePositionService: RoutePositionService,
		private journeyOfflineService: JourneyOfflineService,
		private campaignDistributionService: CampaignDistributionService
	) {
		const revealTouchGame = JSON.parse(localStorage.getItem("reveal-touch-game"));
		const chooseVenueObj = JSON.parse(localStorage.getItem("choose-venue-display"));
		const spotMlbGame = JSON.parse(localStorage.getItem("spot-mlb-game"));
		const hasValue = JSON.parse(localStorage.getItem("hasValue"));

		// if (!(chooseVenueObj || hasValue || revealTouchGame)) {
		// 	// Redirect to the game-chooser page if the user already won int the wheel
		// 	this.router.navigate(["../game-chooser"], {
		// 		relativeTo: this.activatedRoute,
		// 	});
		// }
	}

	ngOnInit() {
		this.routeGuard.markRouteAsVisited("wheel");

		this.initializeLogVerfication();

		// Check if this route is the final step
		this.isFinalStep = this.routePositionService.getRouteOrderPosition("wheel");

		// Add step to the journey offline
		this.journeyOfflineService.addStep("wheel", false);

		// check stand alone mode
		this.checkStandaloneMode();

		// Update isOnline
		this.updateOnlineStatus();
		window.addEventListener("online", this.updateOnlineStatus.bind(this));

		this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
			if (data != null) {
				this.lasData = data;
			}
		});

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				console.log(data);
				this.campaign = data.campaign;
				this.campaignHeader = data.campaign.theme.header;
				this.footerText = data.campaign.theme.footer;

				// Wheel Data
				this.campaignTheme = data.campaign.theme;
				this.wheelData = data.campaign.steps.filter((e) => e.__component === "wheel.wheel")[0];

				this.headerTitle = this.wheelData.headerTitle;
				this.footerBtnText = this.wheelData?.ctaText;

				this.wheelHeaderOpt = this.wheelData.isHeaderEnabled;
				this.wheelFooterOpt = this.wheelData.isFooterEnabled;

				this.isRegionRequired = this.wheelData.isRegionRequired;
				this.isUCRequried = this.wheelData.isUcRequired;

				// Log step
				this.stepName = this.wheelData.stepLabel;
				const stepLebelFormated = this.removeSpacesBetweenWords(this.wheelData.stepLabel);
				// Check if the wheel log step is exicted and if already the logVerfication object have values
				if (!this.wheelLogVerfication[stepLebelFormated] && Object.keys(this.wheelLogVerfication).length) {
					this.stepName = this.wheelData.stepLabel;
					this.logStep(this.lasData.journey, this.stepName);

					this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				}

				// Set theme --------------------
				const theme = data.campaign.theme;
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.wheelData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;

				// Lang
				this.lang = data.campaign.lang;
			}
		});

		this.form = this.fb.group({
			uc: ["", [Validators.required]],
			region: [null, Validators.required],
		});
	}

	// Checks if the app is running in standalone mode
	private checkStandaloneMode(): void {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			this.isStandalone = true;
		}
	}

	// Updates the online/offline status
	private updateOnlineStatus(): void {
		this.isOnline = window.navigator.onLine;
		console.log("updateOnlineStatus", this.isOnline);
	}

	// --------------------------------------------------------------------------------------------
	// @ Wheel products
	// --------------------------------------------------------------------------------------------

	// =================================================================

	ngAfterViewInit(): void {
		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				// Wheel primary color
				if (this.wheelData.primaryColor) {
					this.primaryColor = this.wheelData.primaryColor;
				} else {
					this.primaryColor = this.campaignTheme.color;
				}

				// Wheel secondary color
				if (this.wheelData.secondaryColor) {
					this.secondaryColor = this.wheelData.secondaryColor;
				} else {
					this.secondaryColor = "#FFFFFF";
				}

				// Product Data
				this.products = data.campaign.winnings.map((item: any) => ({
					image: this.backendURL + item.product.imgWheel.url,
					imageRadius: 0.6,
					imageScale: 0.4,
				}));

				// Add empty product
				const newProduct = {
					image: "/assets/las/images/cancel.png",
					imageRadius: 0.6,
					imageScale: 0.4,
				};
				this.products.push(newProduct);

				// Slag
				this.slug = data.campaign.slug;

				// Add the wheel element and implement the wheel functionality
				this.props = {
					name: "Movies",
					isInteractive: false,

					borderWidth: 30,
					borderColor: this.primaryColor,

					itemBackgroundColors: [this.primaryColor, this.secondaryColor],

					lineWidth: 2,
					lineColor: this.campaignTheme.bgColor,

					rotationSpeedMax: 700,
					overlayImage: "/assets/las/images/wheelOverlay.png",
					rotationResistance: -70,

					items: this.products,
				};

				// Integration of the regular expression in the wheel input
				const inputElement = document.getElementById("wheelInputId") as HTMLInputElement;
				if (inputElement) {
					inputElement.addEventListener("input", (event: Event) => {
						const input = event.target as HTMLInputElement;
						const value = input.value;

						const newValue = value.replace(/[^a-zA-Z0-9]/g, "");

						if (value !== newValue) {
							input.value = newValue;
						}
					});
				}

				const container = document.querySelector(".wheel-wrapper");
				const btnSpin = document.getElementById("wheelBtn");
				if (container !== null) {
					// @ts-ignore
					this.wheel = new Wheel(container, this.props);

					// Check if the wheel is spinning/spinned in PWA Online/Offline
					if (this.isStandalone === false || (this.isStandalone === true && this.isOnline === true)) {
						this.wheel.onSpin = (e) => {
							this.wheelSpinning = true;

							// Check if the product is type empty or not
							if (this.products.length - 1 === e.targetItemIndex) {
								this.isEmptyProduct = true;
							} else {
								this.winningProduct = data.campaign.winnings[e.targetItemIndex];
								// this.winningProductModal = this.backendURL + this.winningProduct.product?.img.url;
								// console.log(this.winningProductModal);
							}
						};

						this.wheel.onRest = (e) => {
							if (!this.isEmptyProduct) {
								this.wheelSpinned = true;
								this.wheelSpinning = false;

								// Save winningProduct in localStorage
								localStorage.setItem("winningProduct", JSON.stringify(this.winningProduct));

								let storedProduct = JSON.parse(localStorage.getItem("winningProduct"));
								storedProduct.slug = this.slug;
								localStorage.setItem("winningProduct", JSON.stringify(storedProduct));

								setTimeout(() => {
									localStorage.removeItem("reveal-touch-game");
									localStorage.removeItem("choose-venue-display");
									localStorage.removeItem("spot-mlb-game");
									localStorage.setItem("hasValue", "false");

									if (this.isFinalStep) {
										localStorage.removeItem("logVerfication");
										localStorage.removeItem("visitedRoutes");
									}

									this.router.navigate(["../wheel/result"], {
										relativeTo: this.activatedRoute,
									});
								}, 400);
							} else {
								this.wheelSpinned = true;
								this.wheelSpinning = false;
								this.isEmptyProduct = false;

								setTimeout(() => {
									localStorage.removeItem("reveal-touch-game");
									localStorage.removeItem("choose-venue-display");
									localStorage.removeItem("spot-mlb-game");
									localStorage.setItem("hasValue", "false");

									if (this.isFinalStep) {
										localStorage.removeItem("logVerfication");
										localStorage.removeItem("visitedRoutes");
									}

									this.router.navigate(["../wheel/result"], {
										relativeTo: this.activatedRoute,
									});
								}, 400);
							}
						};
					} else if (this.isStandalone === true && this.isOnline === false) {
						this.wheel.onSpin = async (e) => {
							this.wheelSpinning = true;

							// Check if the product is type empty or not
							if (this.products.length - 1 === e.targetItemIndex) {
								this.isEmptyProduct = true;
							} else {
								this.winningProduct = data.campaign.winnings[e.targetItemIndex];

								// Check if we have a current journey, then add a winning prodcut to the journey
								const currentJourney = localStorage.getItem("currentJourney") ? JSON.parse(localStorage.getItem("currentJourney")) : null;
								if (currentJourney) {
									// Add the winning object to the "currentJourney"
									currentJourney.winning = {
										product: this.winningProduct.product?.id,
										createdAt: this.formatDate(),
									};

									// Update the "currentJourney" in localStorage with new data
									localStorage.setItem("currentJourney", JSON.stringify(currentJourney));
								}
							}
						};

						this.wheel.onRest = (e) => {
							if (!this.isEmptyProduct) {
								this.wheelSpinnedOffline = true;
								this.wheelSpinned = true;
								this.wheelSpinning = false;

								// this.handlePDFModel();

								// Save winningProduct in localStorage
								localStorage.setItem("winningProduct", JSON.stringify(this.winningProduct));

								let storedProduct = JSON.parse(localStorage.getItem("winningProduct"));
								storedProduct.slug = this.slug;
								localStorage.setItem("winningProduct", JSON.stringify(storedProduct));

								// Retrieve the current array of spins from local storage, or create an empty array if none exists
								let storedSpins = JSON.parse(localStorage.getItem("storedSpinsOffline")) || [];

								// Add the current spin to the array
								let spinResult = {
									...storedProduct,
								};
								storedSpins.push(spinResult);

								// Save the updated array back to local storage
								localStorage.setItem("storedSpinsOffline", JSON.stringify(storedSpins));

								setTimeout(() => {
									localStorage.removeItem("reveal-touch-game");
									localStorage.removeItem("choose-venue-display");
									localStorage.removeItem("spot-mlb-game");
									localStorage.setItem("hasValue", "false");

									if (this.isFinalStep) {
										localStorage.removeItem("logVerfication");
										localStorage.removeItem("visitedRoutes");
									}

									this.router.navigate(["../wheel/result"], {
										relativeTo: this.activatedRoute,
									});
								}, 400);
							} else {
								this.wheelSpinnedOffline = true;
								this.wheelSpinned = true;
								this.wheelSpinning = false;
								this.isEmptyProduct = false;

								// this.handlePDFModel();

								setTimeout(() => {
									localStorage.removeItem("reveal-touch-game");
									localStorage.removeItem("choose-venue-display");
									localStorage.removeItem("spot-mlb-game");
									localStorage.setItem("hasValue", "false");

									if (this.isFinalStep) {
										localStorage.removeItem("logVerfication");
										localStorage.removeItem("visitedRoutes");
									}

									this.router.navigate(["../wheel/result"], {
										relativeTo: this.activatedRoute,
									});
								}, 400);
							}
						};
					}
				}
			}
		});
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onRegionChange(event: Event) {
		this.selectedRegion = (event.target as HTMLSelectElement).value;
	}

	async onFooterBtnClick() {
		// Form condition
		if (this.isStandalone === false) {
			// Check if the input is required or not
			if (this.isUCRequried === true) {
				// Get unique code and set it in the localStorage
				const uc = this.form.get("uc").value.toUpperCase();
				localStorage.setItem("uniqueCode", uc);
				this.isBtnDisabled = true;
				const brand = this.campaign.brand.name == "L&M" ? "LM" : this.campaign.brand.name;
				this.restApiService
					.searchUC(uc, brand)
					.pipe(takeUntil(this.unsubscriber))
					.subscribe({
						next: async ({ data }) => {
							this.isUcUsed = data.used;

							// Use unique code
							if (!this.isUcUsed) {
								this.restApiService
									.useUC(uc, brand)
									.pipe(takeUntil(this.unsubscriber))
									.subscribe({
										next: ({ data }) => {},
										error: (error) => {
											if (error.status === 404) {
												console.log(error.message);
											}
										},
									});
							}

							// Wheel condition
							if (!this.wheelSpinning && !this.isUcUsed) {
								if (!this.wheelSpinned) {
									// ==================== WHEEL SPIN DEBUG ====================
									try {
										console.log("==================== WHEEL SPIN DEBUG ====================");

										const hostessInfo = JSON.parse(localStorage.getItem("hostessInfo"));
										const { consumptionMap } = await this.campaignDistributionService.getCampaignDistribution(this.campaign.id, hostessInfo.user.id);
										const availableProducts = this.campaignDistributionService.getTodayAvailableProducts(this.campaign, consumptionMap, hostessInfo.user.id);

										// First, calculate remaining quantities after considering today's consumption
										const productAvailability = new Map<number, number>();
										this.campaign.winnings.forEach((winning) => {
											const productId = winning.product.id;
											const consumed = consumptionMap.get(productId)?.byDate.get(new Date().toDateString()) || 0;
											const allocated = availableProducts.get(productId) || 0;
											const remaining = allocated - consumed;
											productAvailability.set(productId, remaining);
											console.log(`Product ${winning.product.name}:`, {
												id: productId,
												allocated,
												consumed,
												remaining: remaining,
											});
										});

										// Check if any product has remaining quantity
										const anyProductAvailable = Array.from(productAvailability.values()).some((remaining) => remaining > 0);
										console.log(
											"Product availability after consumption:",
											Array.from(productAvailability.entries()).map(([id, remaining]) => ({
												product: this.campaign.winnings.find((w) => w.product.id === id)?.product.name,
												remaining,
											}))
										);
										console.log("Any products still available?", anyProductAvailable);

										if (anyProductAvailable) {
											// Use productAvailability instead of availableProducts for the rest of the logic
											const validProductIndices = [...productAvailability.entries()]
												.filter(([_, remaining]) => remaining > 0)
												.map(([productId, _]) => {
													const index = this.campaign.winnings.findIndex((w) => w.product.id === productId);
													return index;
												});

											// Calculate probabilities
											const totalAvailable = Array.from(availableProducts.values()).reduce((a, b) => a + b, 0);
											const productProbabilities = validProductIndices.map((index) => {
												const product = this.campaign.winnings[index];
												const available = availableProducts.get(product.product.id) || 0;
												const probability = available / totalAvailable;
												console.log(`${product.product.name} probability:`, probability);
												return probability;
											});

											// Select index based on probabilities
											const random = Math.random();
											let cumulative = 0;
											let selectedIndex = validProductIndices[0];

											for (let i = 0; i < productProbabilities.length; i++) {
												cumulative += productProbabilities[i];
												if (random < cumulative) {
													selectedIndex = validProductIndices[i];
													break;
												}
											}

											console.log("Final selected index:", selectedIndex);
											console.log("Selected product:", selectedIndex < this.campaign.winnings.length ? this.campaign.winnings[selectedIndex].product.name : "Empty Slot");

											this.wheel.spinToItem(selectedIndex, 2000, false, 5);
										} else {
											console.log("All products reached daily limit, spinning to empty slot");
											this.wheel.spinToItem(this.products.length - 1, 2000, false, 5);
										}
										console.log("======================================================");
									} catch (error) {
										console.error("Error during wheel spin:", error);
										this.wheel.spinToItem(this.products.length - 1, 2000, false, 5);
									}
									// ==================== / WHEEL SPIN DEBUG ====================

									// this.wheel.spinToItem(this.getRandomIndex(this.products), 2000, false, 5);
									// this.wheel.spinToItem(5, 2000, false, 5);

									this.inputWarningMessage = "";
									this.isUcNotFound = false;
									this.isBtnDisabled = false;

									// Log step
									// this.logStep(this.lasData.journey, this.stepName, { ucUsed: uc, productWon: this.winningProduct.product.name });
									if (this.isEmptyProduct) {
										this.logStep(this.lasData.journey, `Wheel - Lost`, {
											// ucUsed: uc,
											...(this.isFinalStep && { isFinalStep: true }),
										});

										// Add step to the journey offline
										this.journeyOfflineService.addStep("Wheel - Lost", this.isFinalStep);

										localStorage.setItem("empty", "true");
										localStorage.setItem("hasWon", "true");
									} else {
										this.logStep(this.lasData.journey, `Wheel - Win - UC used - ${uc}`, {
											// ucUsed: uc,
											// productWon: this.winningProduct.product.name,
											...(this.isFinalStep && { isFinalStep: true }),
										});

										// Add step to the journey offline
										this.journeyOfflineService.addStep("Wheel - Win", this.isFinalStep);

										localStorage.setItem("empty", "false");
										localStorage.setItem("hasWon", "true");
									}
								} else {
									location.href = "/las/tutorial";
								}
							}

							// Enable button
							if (this.isUcUsed) {
								this.inputWarningMessage = "Le code est déja utilisé";
								this.isBtnDisabled = false;

								// Log step
								this.logStep(this.lasData.journey, `Wheel - UC already used - ${uc}`, {
									// ucUsed: uc,
								});
							}
						},
						error: (error) => {
							if (error.status === 404) {
								this.isUcNotFound = true;
								this.inputWarningMessage = "Le code n'est pas valide";
								this.isBtnDisabled = false;

								// Log step
								this.logStep(this.lasData.journey, `Wheel - UC not found - ${uc}`, {
									// ucUsed: uc,
								});
							}
						},
					});
			} else {
				// Wheel condition
				if (!this.wheelSpinning) {
					if (!this.wheelSpinned) {
						// ==================== WHEEL SPIN DEBUG ====================
						try {
							const hostessInfo = JSON.parse(localStorage.getItem("hostessInfo"));

							const { consumptionMap } = await this.campaignDistributionService.getCampaignDistribution(this.campaign.id, hostessInfo.user.id);
							const availableProducts = this.campaignDistributionService.getTodayAvailableProducts(this.campaign, consumptionMap, hostessInfo.user.id);

							console.log("==================== WHEEL SPIN DEBUG ====================");
							// First, calculate remaining quantities after considering today's consumption

							const productAvailability = new Map<number, number>();
							this.campaign.winnings.forEach((winning) => {
								const productId = winning.product.id;
								const consumed = consumptionMap.get(productId)?.byDate.get(new Date().toDateString()) || 0;
								const allocated = availableProducts.get(productId) || 0;
								const remaining = allocated - consumed;
								productAvailability.set(productId, remaining);
								console.log(`Product ${winning.product.name}:`, {
									id: productId,
									allocated,
									consumed,
									remaining: remaining,
								});
							});

							// Check if any product has remaining quantity
							const anyProductAvailable = Array.from(productAvailability.values()).some((remaining) => remaining > 0);
							console.log(
								"Product availability after consumption:",
								Array.from(productAvailability.entries()).map(([id, remaining]) => ({
									product: this.campaign.winnings.find((w) => w.product.id === id)?.product.name,
									remaining,
								}))
							);
							console.log("Any products still available?", anyProductAvailable);

							if (anyProductAvailable) {
								// Use productAvailability instead of availableProducts for the rest of the logic
								const validProductIndices = [...productAvailability.entries()]
									.filter(([_, remaining]) => remaining > 0)
									.map(([productId, _]) => {
										const index = this.campaign.winnings.findIndex((w) => w.product.id === productId);
										return index;
									});

								// Calculate probabilities
								const totalAvailable = Array.from(availableProducts.values()).reduce((a, b) => a + b, 0);
								const productProbabilities = validProductIndices.map((index) => {
									const product = this.campaign.winnings[index];
									const available = availableProducts.get(product.product.id) || 0;
									const probability = available / totalAvailable;
									console.log(`${product.product.name} probability:`, probability);
									return probability;
								});

								// Select index based on probabilities
								const random = Math.random();
								let cumulative = 0;
								let selectedIndex = validProductIndices[0];

								for (let i = 0; i < productProbabilities.length; i++) {
									cumulative += productProbabilities[i];
									if (random < cumulative) {
										selectedIndex = validProductIndices[i];
										break;
									}
								}

								console.log("Final selected index:", selectedIndex);
								console.log("Selected product:", selectedIndex < this.campaign.winnings.length ? this.campaign.winnings[selectedIndex].product.name : "Empty Slot");

								this.wheel.spinToItem(selectedIndex, 2000, false, 5);
							} else {
								console.log("All products reached daily limit, spinning to empty slot");
								this.wheel.spinToItem(this.products.length - 1, 2000, false, 5);
							}
							console.log("======================================================");
						} catch (error) {
							console.error("Error during wheel spin:", error);
							this.wheel.spinToItem(this.products.length - 1, 2000, false, 5);
						}
						// ==================== / WHEEL SPIN DEBUG ====================

						// this.wheel.spinToItem(this.getRandomIndex(this.products), 2000, false, 5);
						// this.wheel.spinToItem(5, 2000, false, 5);

						this.isUcNotFound = false;
						this.isBtnDisabled = false;

						// Log step
						if (this.isEmptyProduct) {
							this.logStep(this.lasData.journey, "Wheel - Lost", { ...(this.isFinalStep && { isFinalStep: true }) });

							// Add step to the journey offline
							this.journeyOfflineService.addStep("Wheel - Lost", this.isFinalStep);

							localStorage.setItem("empty", "true");
							localStorage.setItem("hasWon", "true");
						} else {
							// productWon: this.winningProduct.product.name
							this.logStep(this.lasData.journey, "Wheel - Win", { ...(this.isFinalStep && { isFinalStep: true }) });

							// Add step to the journey offline
							this.journeyOfflineService.addStep("Wheel - Win", this.isFinalStep);

							localStorage.setItem("empty", "false");
							localStorage.setItem("hasWon", "true");
						}
					}
				}
			}
		} else if (this.isStandalone === true && this.isUCRequried === false) {
			// Skip UC check and proceed with spinning the wheel
			// Wheel condition
			if (!this.wheelSpinning && !this.isUcUsed) {
				if (!this.wheelSpinnedOffline) {
					this.wheel.spinToItem(this.getRandomIndex(this.products), 2000, false, 5);
					// this.wheel.spinToItem(5, 2000, false, 5);
					this.inputWarningMessage = "";
					this.isUcNotFound = false;
					this.isBtnDisabled = false;

					// Handle empty condition within the winning
					if (this.isEmptyProduct) {
						// Add step to the journey offline
						this.journeyOfflineService.addStep("Wheel - Lost", this.isFinalStep);

						localStorage.setItem("empty", "true");
						localStorage.setItem("hasWon", "true");

						this.logStep(this.lasData.journey, "Wheel - Lost", { ...(this.isFinalStep && { isFinalStep: true }) });
					} else {
						// Add step to the journey offline
						this.journeyOfflineService.addStep("Wheel - Win", this.isFinalStep);

						localStorage.setItem("empty", "false");
						localStorage.setItem("hasWon", "true");

						this.logStep(this.lasData.journey, "Wheel - Win", { ...(this.isFinalStep && { isFinalStep: true }) });
					}
				}
			}
		}
	}

	saveSpinResultLocally(uc: string) {
		const id = this.winningProduct?.product?.id;
		if (id) {
			localStorage.setItem("spinResult", id);
			this.winningProductOffline = id;
		} else {
			console.error("No winning product found!");
		}
	}

	handlePDFModel() {
		this.modalEditRef = this.modalService.open(this.modalEdit, { size: this.editModalSize, centered: true });
	}

	onSubmitForm() {}

	// --------------------------------------------------------------------------------------------
	// @ Functions
	// --------------------------------------------------------------------------------------------
	formatDate(): string {
		const now = new Date();
		return (
			now.getFullYear() +
			"-" +
			String(now.getMonth() + 1).padStart(2, "0") +
			"-" +
			String(now.getDate()).padStart(2, "0") +
			" " +
			String(now.getHours()).padStart(2, "0") +
			":" +
			String(now.getMinutes()).padStart(2, "0") +
			":" +
			String(now.getSeconds()).padStart(2, "0")
		);
	}

	getRandomIndex(array: any[]): number {
		return Math.floor(Math.random() * array.length);
	}

	isRegionSelected(): boolean {
		return this.selectedRegion !== "";
	}

	isWheelInputFilled(): boolean {
		return this.wheelInput.trim().length >= 5;
	}

	handleButtonDisabled() {
		const regionCondition = this.isRegionRequired ? this.isRegionSelected() : true;
		const inputCondition = this.isUCRequried ? this.isWheelInputFilled() : true;
		const wheelCondition = !this.wheelSpinned && (this.wheelSpinning || !(regionCondition && inputCondition));
		return this.isBtnDisabled || wheelCondition;
	}

	logStep(journeyId: number, stepLabel: string, additionalData = {}) {
		console.log(journeyId, stepLabel, additionalData);

		this.lasService
			.logStep(journeyId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey wheel created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.wheelLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.wheelLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.wheelLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}
