import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WheelComponent } from "./code/wheel.component";
import { ResultComponent } from "./result/result.component";

const routes: Routes = [
	{ path: "", component: WheelComponent },
	{ path: "wheel/result", component: ResultComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class WheelRoutingModule {}
