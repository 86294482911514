import { Injectable, TemplateRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

export interface Toast {
	message: string;
	status?: "success" | "danger";
	delay?: number;
}

@Injectable({ providedIn: "root" })
export class UtilsService {
	toastSubject = new BehaviorSubject<any>(null);
	toasts: Toast[] = [];
	constructor() {}

	requiredValidator(control: FormControl) {
		const isWhitespace = (control.value || "").trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { required: true };
	}

	getColValue(obj: any, path: string): any {
		return path.split(".").reduce((acc, part) => (acc ? acc[part] : undefined), obj);
	}

	showToast(toast: Toast) {
		this.toasts.push(toast);
	}

	removeToast(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clearToasts() {
		this.toasts.splice(0, this.toasts.length);
	}

	findInvalidControls(form) {
		const invalid = [];
		const controls = form.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		console.log(invalid);
		return invalid;
	}
}
