<!-- Main -->
<main [ngStyle]="ngStyle">
	<!-- Header section -->
	@if (dataColHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	<section class="content-container" id="content-container">
		<div class="main-content">
			<form [formGroup]="form" (ngSubmit)="onFooterBtnClick()" class="input-wrapper">
				@if (isEmailRequired) {
					<div class="input-group-email">
						<div class="input-group">
							<span class="input-group-text bg-white border-0 input-span-1">
								<img class="input-icon" src="/assets/las/images/envelope.svg" alt="" />
							</span>
							<input formControlName="email" type="email" id="emailInputId" class="form-control border-0" [placeholder]="emailPlaceholder" autocomplete="off" [(ngModel)]="emailInput" />
							<span class="input-group-text bg-white border-0 input-span-2"></span>
						</div>
					</div>
				}

				@if (isPhoneRequired) {
					<div class="input-group-phone">
						<div class="input-group">
							<span class="input-group-text bg-white border-0 input-span-1">
								<img class="input-icon" src="/assets/las/images/telephone.svg" alt="" />
							</span>
							<input formControlName="phone" type="text" id="phoneInputId" class="form-control border-0" [placeholder]="phonePlaceholder" autocomplete="off" [(ngModel)]="phoneInput" />
							<span class="input-group-text bg-white border-0 input-span-2"></span>
						</div>
					</div>
				}
			</form>
		</div>
	</section>

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button
				[disabled]="!isInputsValid()"
				[ngStyle]="{
					color: colorCTA,
					'background-color': bgColorCTA,
					border: borderCTA
				}"
				class="btn btn-danger footer-btn"
				(click)="onFooterBtnClick()"
			>
				{{ footerBtnText }}
			</button>
		</div>

		@if (dataColFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>
