import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class CacheAssetsService {
	private assetsUrl = "/assets/las/images/";
	private imageListUrl = "/assets/images-list.json";

	constructor(private http: HttpClient) {}

	// Returns a Promise that resolves when all images are cached
	fetchImages(): Promise<number> {
		return new Promise((resolve, reject) => {
			this.http.get<string[]>(this.imageListUrl).subscribe(
				(imageFiles) => {
					// Create an array of Promises for caching each image
					const cachePromises = imageFiles.map((file) => this.cacheImage(file));

					// Wait for all caching Promises to complete
					Promise.all(cachePromises)
						.then(() => {
							console.log("All images cached successfully");
							resolve(imageFiles.length);
						})
						.catch((error) => {
							console.error("An error occurred while caching images", error);
							reject(error);
						});
				},
				(error) => {
					console.error("Failed to fetch image list", error);
					reject(error);
				}
			);
		});
	}

	private cacheImage(filename: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const imageUrl = `${this.assetsUrl}${filename}`;
			this.http.get(imageUrl, { responseType: "blob" }).subscribe(
				(blob) => {
					const cacheName = "ngsw:/:assets-cached";
					if ("caches" in window) {
						caches.open(cacheName).then((cache) => {
							cache.put(`${this.assetsUrl}${filename}`, new Response(blob)).then(() => {
								resolve();
							});
						});
					} else {
						reject(new Error("Cache API not supported in this browser"));
					}
				},
				(error) => {
					console.error(`Failed to fetch image: ${imageUrl}`, error);
					reject(error);
				}
			);
		});
	}
}
