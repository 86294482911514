import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "gm-toast",
	template: `
		<div class="toast-container position-fixed bottom-0 start-50 translate-middle-x" style="padding-bottom: 15px">
			<ngb-toast [autohide]="true" [delay]="1500" class="bg-{{ status }} text-light text-center">{{ message }}</ngb-toast>
		</div>
	`,
})
export class ToastComponent implements OnInit {
	@Input() status: string;
	@Input() message: string;

	constructor() {}

	ngOnInit(): void {}
}
