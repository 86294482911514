import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UrlService {
	private urlSubject = new Subject<string>();

	// Variable Listener
	urlChanged$ = this.urlSubject.asObservable();

	setUrl(url: string) {
		this.urlSubject.next(url);
	}
}
