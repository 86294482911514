import { Injectable } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "./las.service";

// Define step structure
interface JourneyStep {
	stepLabel: string;
	createdAt: string;
}

// Define journey structure
interface Journey {
	lasUUID: string;
	createdAt: string;
	campaign: number;
	steps: JourneyStep[];
}

@Injectable({
	providedIn: "root",
})
export class JourneyOfflineService {
	// Storage keys
	private readonly COMPLETED_KEY = "completedJourneys";
	private readonly DEVICE_KEY = "device_fingerprint";
	private readonly CURRENT_KEY = "currentJourney";
	private readonly HOSTESS_KEY = "hostessInfo";

	private unsubscriber = new Subject();
	private currentCampaignId: number | null = null;

	constructor(private lasService: LasService) {
		// Subscribe to campaign data
		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data) {
				this.currentCampaignId = data.campaign?.id;
			}
		});
	}

	// Helper method to check if service should be operational
	private isServiceOperational(): boolean {
		return !navigator.onLine && localStorage.getItem(this.HOSTESS_KEY) ? true : false;
	}

	// Format date to YYYY-MM-DD HH:mm:ss
	private formatDate(): string {
		const now = new Date();
		return (
			now.getFullYear() +
			"-" +
			String(now.getMonth() + 1).padStart(2, "0") +
			"-" +
			String(now.getDate()).padStart(2, "0") +
			" " +
			String(now.getHours()).padStart(2, "0") +
			":" +
			String(now.getMinutes()).padStart(2, "0") +
			":" +
			String(now.getSeconds()).padStart(2, "0")
		);
	}

	// Add new step to journey
	addStep(stepName: string, isFinalStep: boolean): void {
		if (!this.isServiceOperational()) {
			console.log("Service not operational: Device is online or no device fingerprint found");
			return;
		}

		// Get device fingerprint
		const lasUUID = localStorage.getItem(this.DEVICE_KEY);
		if (!lasUUID) {
			console.error("No device fingerprint found");
			return;
		}

		// Get current journey if exists
		let currentJourney: Journey | null = null;
		const currentData = localStorage.getItem(this.CURRENT_KEY);
		if (currentData) {
			currentJourney = JSON.parse(currentData);
		}

		// Check for duplicate step
		if (currentJourney?.steps.some((step) => step.stepLabel === stepName)) {
			console.log(`Step "${stepName}" already exists in current journey`);
			return;
		}

		// Create new step
		const step: JourneyStep = {
			stepLabel: stepName,
			createdAt: this.formatDate(),
		};

		// Initialize new journey if needed
		if (!currentJourney) {
			if (!this.currentCampaignId) {
				console.error("No campaign ID available");
				return;
			}

			currentJourney = {
				lasUUID,
				createdAt: this.formatDate(),
				campaign: this.currentCampaignId,
				steps: [],
			};
		}

		// Add step to current journey
		currentJourney.steps.push(step);
		localStorage.setItem(this.CURRENT_KEY, JSON.stringify(currentJourney));

		// Handle journey completion
		if (isFinalStep) {
			const completed = this.getCompletedJourneys();
			completed.push(currentJourney);
			localStorage.setItem(this.COMPLETED_KEY, JSON.stringify(completed));
			localStorage.removeItem(this.CURRENT_KEY);
		}
	}

	// Get all completed journeys from storage
	getCompletedJourneys(): Journey[] {
		if (!this.isServiceOperational()) {
			console.log("Service not operational: Device is online or no device fingerprint found");
			return [];
		}
		const data = localStorage.getItem(this.COMPLETED_KEY);
		return data ? JSON.parse(data) : [];
	}

	// Get current active journey from storage
	getCurrentJourney(): Journey | null {
		if (!this.isServiceOperational()) {
			console.log("Service not operational: Device is online or no device fingerprint found");
			return null;
		}
		const data = localStorage.getItem(this.CURRENT_KEY);
		return data ? JSON.parse(data) : null;
	}

	// Clear all journey data from storage
	clearAll(): void {
		if (!this.isServiceOperational()) {
			console.log("Service not operational: Device is online or no device fingerprint found");
			return;
		}
		localStorage.removeItem(this.CURRENT_KEY);
		localStorage.removeItem(this.COMPLETED_KEY);
	}

	// Cleanup subscriptions
	ngOnDestroy() {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
}
