import { Component, AfterViewInit, OnDestroy, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { DOCUMENT } from "@angular/common";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { JourneyOfflineService } from "../core/services/journey.offline.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";

@Component({
	selector: "app-game-chooser",
	templateUrl: "./game-chooser.component.html",
	styleUrl: "./game-chooser.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class GameChooserComponent implements OnInit, OnDestroy {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;
	unlockedIcon = "assets/las/images/unlocked.svg";
	lockedIcon = "assets/las/images/locked.svg";
	activeSlideIndex: number;
	events: any;
	slides: any;
	gameChooserScreen: any;
	productChooser: any;
	footerBtnText: string;
	slideConfig: any;
	campaignLang: "ar" | "fr" | "en";
	campaignTheme: any;
	footerText: string;
	headLineText: string;
	gameChooserHeaderOpt: boolean;
	gameChooserFooterOpt: boolean;

	// LAS Log
	lasData: any;
	stepName: string;
	gameChooserLogVerfication: any;
	campaignHeader: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;
	footerRedirectUrl: string;

	// Routing config
	isAnyNextRoute: any;

	// Campaign CTA Text
	ctaNextText: string;
	ctaRetryText: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		@Inject(DOCUMENT) private document: Document,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private journeyOfflineService: JourneyOfflineService,
		private routingOrderService: RoutingOrderService
	) {}

	ngOnInit(): void {
		this.routeGuard.markRouteAsVisited("game-chooser");

		// Add step to the journey offline
		this.journeyOfflineService.addStep("game-chooser", false);

		this.initializeLogVerfication();

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignHeader = data.campaign.theme.header;
				this.footerText = data.campaign.theme.footer;

				this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
				this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

				// Set direction based on language
				this.campaignLang = data.campaign.lang; // data.campaign.lang

				// campaignTheme
				this.campaignTheme = data.campaign.theme;

				this.gameChooserScreen = data.campaign.steps.filter((e) => e.__component === "screen.game-chooser")[0];

				this.footerRedirectUrl = this.gameChooserScreen?.items[0].url;

				this.headLineText = this.gameChooserScreen.headLine;

				// Set CTA text
				this.isAnyNextRoute = this.routingOrderService.getNextRoute("game-chooser");
				this.footerBtnText = this.gameChooserScreen?.ctaText;
				// if (!this.isAnyNextRoute) {
				// 	this.footerBtnText = this.ctaRetryText;
				// } else {
				// 	if (this.gameChooserScreen?.ctaText) {
				// 		this.footerBtnText = this.gameChooserScreen?.ctaText;
				// 	} else {
				// 		this.footerBtnText = this.ctaNextText;
				// 	}
				// }

				this.gameChooserHeaderOpt = this.gameChooserScreen.isHeaderEnabled;
				this.gameChooserFooterOpt = this.gameChooserScreen.isFooterEnabled;

				// Log step
				const stepLebelFormated = this.removeSpacesBetweenWords(this.gameChooserScreen.stepLabel);
				if (!this.gameChooserLogVerfication[stepLebelFormated]) {
					this.stepName = this.gameChooserScreen.stepLabel;
					this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
						if (data != null) {
							this.lasData = data;
							this.logStep(this.lasData.journey, this.stepName);
						}
					});

					this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				}

				// Set theme --------------------
				const theme = data.campaign.theme;
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.gameChooserScreen);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.productChooser = data.campaign.winnings;
				this.activeSlideIndex = Math.floor(this.productChooser.length / 2);

				console.log(this.productChooser);
				this.slideConfig = {
					accessibility: true,
					adaptiveHeight: true,
					autoplay: true,
					autoplaySpeed: 2000,
					arrows: false,
					centerMode: true,
					centerPadding: "0",
					cssEase: "ease",
					customPaging: null,
					dots: false,
					draggable: true,
					easing: "linear",
					edgeFriction: 0.35,
					fade: false,
					focusOnSelect: true,
					infinite: false,
					lazyLoad: "ondemand",
					mobileFirst: true,
					initialSlide: Math.floor(this.productChooser.length / 2),
					pauseOnHover: true,
					pauseOnDotsHover: false,
					pauseOnFocus: true,
					respondTo: "window",
					rows: 1,
					rtl: this.campaignLang === "ar" ? true : false,
					slide: "div",
					slidesPerRow: 1,
					slidesToShow: 1,
					slidesToScroll: 1,
					speed: 700,
					swipe: true,
					swipeToSlide: true,
					touchMove: true,
					touchThreshold: 5,
					useCSS: true,
					useTransform: true,
					variableWidth: true,
					vertical: false,
					verticalSwiping: false,
					waitForAnimate: true,
					zIndex: 1000,
				};
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	onFooterBtnClick() {
		this.router.navigate([`../${this.footerRedirectUrl}`], { relativeTo: this.activatedRoute });
	}

	slickInit(e: any) {
		const middleIndex = Math.floor(this.productChooser.length / 2);
		this.setActiveClass(middleIndex);
		this.activeSlideIndex = middleIndex;
	}

	breakpoint(e: any) {}

	afterChange(e: any) {
		this.setActiveClass(e.currentSlide);
		this.activeSlideIndex = e.currentSlide;
	}

	beforeChange(e: any) {}

	private setActiveClass(currentSlide: number) {
		const slides = document.querySelectorAll(".item-img-section");
		slides.forEach((slide, index) => {
			if (index === currentSlide) {
				slide.classList.add("active");
			} else {
				slide.classList.remove("active");
			}
		});
	}

	navigateTo(url: string, releaseDate: string): void {
		this.router.navigate(["../" + url], { relativeTo: this.activatedRoute });
	}

	convertDateFormat(dateString: string): string {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}

	isDateEqualOrAboveCurrent(dateString: string): string {
		const inputDate = new Date(dateString);
		const currentDate = new Date();
		return inputDate >= currentDate ? "Coming Soon" : "";
	}

	logStep(journeyId: number, stepLabel: string) {
		this.lasService
			.logStep(journeyId, stepLabel)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey game chooser created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.gameChooserLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.gameChooserLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.gameChooserLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}

	// Convert HEX to RGB
	getRgbColor(): string {
		// Remove the hash at the start if it's there
		const hex = this.campaignTheme.color.charAt(0) === "#" ? this.campaignTheme.color.slice(1) : this.campaignTheme.color;

		// Convert hex to RGB
		const r = parseInt(hex.slice(0, 2), 16);
		const g = parseInt(hex.slice(2, 4), 16);
		const b = parseInt(hex.slice(4, 6), 16);

		// Return the RGB values as a comma-separated string
		return `${r}, ${g}, ${b}`;
	}
}
