import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class RouteOrderGuard {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// First check if the user is already in the final step
		const isFinalStep = localStorage.getItem("isFinalStep");
		const hostessInfo = localStorage.getItem("hostessInfo");
		if (isFinalStep === "true" && hostessInfo && !this.checkStandaloneMode()) {
			window.location.href = "/las/hostess";
			return false; // Prevent further navigation in Angular router
		}

		const routeOrder = this.getRouteOrder();
		const currentRoute = route.routeConfig.path;
		let currentIndex = routeOrder.indexOf(currentRoute);

		// Check for exact match or base route match (for dynamic routes)
		if (currentRoute.includes(":")) {
			const baseRoute = currentRoute.split("/:")[0];
			currentIndex = routeOrder.findIndex((r) => {
				if (r === baseRoute || r === currentRoute) return true;
				if (r.startsWith(baseRoute) && new RegExp(`^${baseRoute}\\/\\d+$`).test(r)) return true;
				return false;
			});
		} else {
			currentIndex = routeOrder.indexOf(currentRoute);
		}

		if (currentIndex === -1) {
			console.warn(`Route ${currentRoute} not found in routeOrder`);
			return false; // Not allow access if the route is not in the routeOrder
		}

		if (currentIndex === 0) {
			return true; // Always allow access to the first route
		}

		const previousRoute = routeOrder[currentIndex - 1];
		const visitedRoutes = this.getVisitedRoutes();

		// Proceed with age verification check
		const isAgeVerified = this.getUserAgeVerificationStatus();

		if (isAgeVerified === "true") {
			// Mark policy is visted, if age verification checked
			this.markRouteAsVisited("policy");
		}

		if (!visitedRoutes.includes(previousRoute)) {
			const slug = JSON.parse(localStorage.getItem("slug"));

			console.log(`las/${slug}/${previousRoute}`);

			return this.router.createUrlTree([`las/${slug}/${previousRoute}`]);
		}

		return true;
	}

	private getRouteOrder(): string[] {
		const storedOrder = localStorage.getItem("routeOrder");
		return storedOrder ? JSON.parse(storedOrder) : [];
	}

	private getVisitedRoutes(): string[] {
		const visitedRoutes = localStorage.getItem("visitedRoutes");
		return visitedRoutes ? JSON.parse(visitedRoutes) : [];
	}

	private getUserAgeVerificationStatus(): string | null {
		return localStorage.getItem("isAgeVerified");
	}

	private getRouteFromUrl(url: string): string {
		const segments = url.split("/");
		return segments[1] || ""; // Get the first segment after the leading slash
	}

	private checkStandaloneMode(): any {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			return true;
		} else {
			return false;
		}
	}

	// Public method to mark a route as visited
	public markRouteAsVisited(route: string): void {
		const visitedRoutes = this.getVisitedRoutes();
		if (!visitedRoutes.includes(route)) {
			visitedRoutes.push(route);
			localStorage.setItem("visitedRoutes", JSON.stringify(visitedRoutes));
		}
	}
}
