import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";

@Injectable({
	providedIn: "root",
})
export class AgeVerificationGuard {
	constructor(
		private routingOrderService: RoutingOrderService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isAgeVerified = this.getUserAgeVerificationStatus();

		if (isAgeVerified === "true") {
			// Age is verified, get the next route and navigate
			const nextRoute = this.getNextRoute("policy");

			console.log(nextRoute);

			const slug = JSON.parse(localStorage.getItem("slug"));

			return this.router.createUrlTree([`las/${slug}/${nextRoute}`]);
		} else {
			// Age is not verified, stay on the current route
			return true;
		}
	}

	private getUserAgeVerificationStatus() {
		const isAgeVerified = localStorage.getItem("isAgeVerified");
		return isAgeVerified;
	}

	private getNextRoute(currentPath: string): string | null {
		const routeOrder = JSON.parse(localStorage.getItem("routeOrder"));

		// Get the index of the current path in route order
		const currentIndex = routeOrder.indexOf(currentPath);

		// Check if is next route available
		if (currentIndex < routeOrder.length - 1) {
			return routeOrder[currentIndex + 1];
		}
		return null;
	}
}
