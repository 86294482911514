import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));

if ("serviceWorker" in navigator && environment.production) {
	window.addEventListener("load", () => {
		// Register Angular's built-in service worker
		navigator.serviceWorker
			.register("/ngsw-worker.js")
			.then((registration) => {
				console.log("Angular ServiceWorker registered: ", registration);
			})
			.catch((error) => {
				console.error("Angular ServiceWorker registration failed: ", error);
			});
	});
}
